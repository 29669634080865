import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import "./styles/article.css";

function Article({settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("articles");
    }, [settingCurrentPageForHeader]);

    const currentArticle = useParams();

    const [articleState, setArticleState] = useState({
        content : null,
        status : false
    });

    useEffect(() => {
        axios.get('https://piotrthel.vxm.pl/wp/wp-json/wp/v2/articles?slug=' + currentArticle.slug + '&_embed')
            .then(function (response) {
                setArticleState({
                    content : response.data[0],
                    status : true
                });
            })
            .catch(function (error) {
                setArticleState({
                    content : <p className="text-center">Ups! Błąd aplikacji</p>,
                    status : false
                });
            })
            .finally(function () {
                if(document.querySelector(".loading")) {
                    document.querySelector(".loading").remove();
                }
            });
    }, [currentArticle.slug]);

    function articleResults(data) {
        let articleContent;
        if(data.status) {
            articleContent = <>
                <article>
                    <h2>{parse(data.content.title.rendered)}</h2>
                    <img src={data.content._embedded ? data.content._embedded["wp:featuredmedia"][0].source_url : ""} alt="" />
                    {parse(data.content.content.rendered)}
                    <p className="author">{parse(data.content.acf.autor)}</p>
                    <hr />
                    {parse(data.content.acf.przypisy)}
                    {data.content.acf.przypisy ? <hr />: null}
                    <p className="date">
                    Data publikacji: {parse(data.content.acf.data_publikacji)}
                    </p>
                </article>
                <div className="center">
                    <Link to="/artykuly" className="btn">Wszystkie artykuły <img src="/img/arrow.svg" alt=">" /></Link>
                </div>
            </>;
        } else {
            articleContent = data.content;
        }
        return articleContent;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding">
                <div className="container">
                    <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                    {articleResults(articleState)}
                </div>
            </div>
        </main>
    );
}

export default Article;