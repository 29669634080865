import { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import parse from 'html-react-parser';
import "./styles/scores.css";

function Scores({imageFrame, setImageFrame, settingCurrentPageForHeader}) {

    useEffect(()=> {
        settingCurrentPageForHeader("scores");
    }, [settingCurrentPageForHeader]);

    function imageFrameInit(src) {
        setImageFrame(true, src);
    }

    const [scoresState, setScoresState] = useState({
        scores : null,
        types : null,
        pageContent : null,
        status : false
    });

    const urls = useMemo(() => {
        return [
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/scores?acf_format=standard&_embed', 
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/scores_type',
        'https://piotrthel.vxm.pl/wp/wp-json/wp/v2/pages/195?acf_format=standard'
    ]}, []);
    const promises = useMemo(() => { return [] }, []);
    const allData = useMemo(() => { return [] }, []);

    useEffect(() => {
        if(!scoresState.status) {
            urls.map((item) => {
                promises.push(
                    axios.get(item)
                );
                return null;
            });
            Promise.all(urls).then(() => {
                promises.map((item, index) => {
                    item.then(value => {
                        allData[index] = value.data;
                    });
                    return null;
                });
                Promise.all(promises).then(() => {
                    setScoresState({
                        scores : allData[0],
                        types : allData[1],
                        pageContent : allData[2],
                        status : true
                    });
                    if(document.querySelector(".loading")) {
                        document.querySelector(".loading").remove();
                    }
                });
            })
        }
    }, [scoresState, allData, promises, urls]);

    function scoresCategory(data, id) {
        let scoresArray = [];
        data.map((item, index) => {
            if(item.scores_type[0] === id) {
                scoresArray.push(
                    <div className="composition" key={index}>
                        <h6>{parse(item.title.rendered)}</h6>
                        <div className="buttons">
                            <a href={item.acf.podglad.url} target="_blank" rel="noreferrer" onClick={(event) => {event.preventDefault();imageFrameInit(item.acf.podglad.url);}}>{scoresState.pageContent.acf.button_jpg}</a>
                            <a href={item.acf.pdf} target="_blank" rel="noreferrer">{scoresState.pageContent.acf.button_pdf}</a>
                        </div>
                    </div>
                );
            }
            return null;
        });
        return scoresArray;
    }

    function isEmpty(data, id) {
        let checkEmpty = true;
        data.map((item, index) => {
            if(item.scores_type[0] === id) {
                checkEmpty = false;
            }
            return null;
        });
        return checkEmpty;
    }

    useEffect(() => {
        
    }, [scoresState.status]);

    function scoresResult(data) {
        let scoresContent = [];
        if(data.status) {
            scoresState.types.map((item, index) => {
                scoresContent.push(
                    <div key={index} className={`scores-container${isEmpty(data.scores, item.id) ? " hide" : " visible"}`}>
                        <h3 className="scores-title">{item.name}</h3>
                        <div className="compositions-container">
                            {scoresCategory(data.scores, item.id)}
                        </div>
                    </div>
                );
                return null;
            });
        } else {
            scoresContent.push(data.content);
        }
        return scoresContent;
    }

    return (
        <main>
            <div className="menu-space-container"></div>
            <div className="standard-padding">
                <div className="container">
                <div className="loading"><img src="/img/loader.png" alt="Wczytywanie..." /></div>
                {scoresResult(scoresState)}
                </div>
            </div>
        </main>
    );
}

export default Scores;